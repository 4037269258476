<template>
  <Swiper
    class="lg:flex-1 max-lg:w-[calc(100%+1.5rem)] max-lg:-mt-3 max-lg:-translate-x-3 max-lg:max-lg:h-70 lg:aspect-square self-start rounded-t-4 lg:rounded-4.5 relative bg-white"
    v-bind="(options as any)"
  >
    <SwiperSlide
      v-for="img in imgs"
      :key="img"
    >
      <NuxtImg
        class="object-center object-cover w-full h-full"
        :width="430"
        :height="430"
        fit="crop"
        loading="lazy"
        :src="img"
      />
    </SwiperSlide>
    <SwiperPagination class="absolute bottom-4 left-1/2 -translate-x-1/2 z-1" />
    <div class="absolute border-b-1 lg:border-1 border-border/50 inset-0 z-1 rounded-t-4 lg:rounded-4.5 pointer-events-none"></div>
  </Swiper>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';
const options: SwiperOptions = {
  modules: [Pagination],
  pagination: {
    clickable: true,
    el: '.swiper-pagination'
  },
  rewind: true,
  slidesPerView: 1
};

defineProps<{
	imgs?: Array<string | undefined>
}>();
</script>
