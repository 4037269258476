<template>
  <button
    type="button"
    :class="[classes, 'uno-nlxql2 .swiper-navigation-disabled:hidden']"
    class="swiper-button i-custom:arrow-right text-6 text-#A4D17E text-firmennyy z-1 cursor-pointer"
  ></button>
</template>

<script setup lang="ts">
const props = defineProps({
  orientation: { type: String as PropType<'right' | 'left'>, default: 'right' }
});

const classes = computed(() => {
  return props.orientation === 'right' ? 'swiper-button-next' : 'swiper-button-prev rotate-180';
});
</script>
